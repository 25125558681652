<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="network-page">
    <section v-if="!loading">
      <div class="row" v-if="network">
        <div class="col-12">
          <b-card class="" title="Network View">
            <b-card-text>This page detail about your network. You can set update and delete the network from this page.</b-card-text>
          </b-card>
        </div>
        <div class="col-9">
          <b-tabs>
            <b-tab title="Details" active>
              <b-card class="">
                <section>
                  <h4 class="mb-1">Details</h4>

                  <section class="row">
                    <div class="col-6">
                      <p class="font-weight-bolder">Name </p>
                      <p class="text-capitalize">{{ network.name }}</p>
                    </div>

                    <div class="col-6">
                      <p class="font-weight-bolder">Auto Connect</p>
                      <p class="text-capitalize">{{ network.auto_connect }}</p>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-bolder">SSID</p>
                      <p>{{ network.ssid }}</p>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-bolder">SSID Hidden</p>
                      <p class="text-capitalize">{{ network.ssid_hidden }}</p>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-bolder">MAC Address Randomization Mode</p>
                      <p>{{ network.mac_randomization_mode }}</p>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-bolder">Network Enabled</p>
                      <p class="text-capitalize">{{ network.enabled }}</p>
                    </div>
                    <div class="col-6"/>
                  </section>
                </section>
              </b-card>
              <b-card class="">
                <section>
                  <h4 class="mb-1">Security Details</h4>

                  <section class="row">
                    <div class="col-6">
                      <p class="font-weight-bolder">Security</p>
                      <p >{{ network.security }}</p>
                    </div>
                    <div v-if="canShowPassphrase()" class="col-6">
                      <p class="font-weight-bolder">Passphrase</p>
                      <div class="d-flex align-items-center">
                        <span>{{ maskedPassphrase }}</span>
                        <i class="fas align-items-center ml-1" :class="showPassphrase ? 'fa-eye' : 'fa-eye-slash'" @click="togglePassphrase"></i>
                      </div>
                    </div>
                    <template v-if="network.security === 'WEP-8021X' || network.security === 'WPA-EAP'">
                      <div class="col-6">
                        <p class="font-weight-bolder">Eap Save Credentials</p>
                        <p class="text-capitalize">{{ network.eap_save_credentials }}</p>
                      </div>
                      <div class="col-6">
                        <p class="font-weight-bolder">Eap Inner</p>
                        <p class="text-capitalize">{{ network.eap_inner }}</p>
                      </div>
                      <div class="col-6">
                        <p class="font-weight-bolder">Eap Outer</p>
                        <p class="text-capitalize">{{ network.eap_outer || '-' }}</p>
                      </div>
                      <div class="col-6">
                        <p class="font-weight-bolder">Eap Identity</p>
                        <p class="text-capitalize">{{ network.eap_identity || '-' }}</p>
                      </div>
                      <div v-if="network.eap_outer !== 'EAP-TLS'" class="col-6">
                        <p class="font-weight-bolder">Eap Password</p>
                        <div class="d-flex align-items-center">
                          <span>{{ maskedPassword }}</span>
                          <i class="fas align-items-center ml-1" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" @click="togglePassword"></i>
                        </div>
                      </div>
                      <template v-if="network.eap_outer === 'EAP-TLS'">
                        <div class="col-6">
                          <p class="font-weight-bolder">Domain Suffix Match (optional)</p>
                          <p class="text-capitalize">{{ network.eap_identity || '-' }}</p>
                        </div>
                        <div class="col-6">
                          <p class="font-weight-bolder">Server CA Certificate (X509)</p>
                          <p class="text-capitalize">{{ maskedServerCertificate }}</p>
                        </div>
                        <div class="col-6">
                          <p class="font-weight-bolder">Client Certificate Type</p>
                          <p class="text-capitalize">{{ network.eap_client_certificate_type || '-' }}</p>
                        </div>
                        <div v-if="network.eap_client_certificate_type === 'Manual'" class="col-6">
                          <p class="font-weight-bolder">Client Certificate (PKCS12)</p>
                          <p class="text-capitalize">{{ maskedClientCertificate }}</p>
                        </div>
                      </template>
                    </template>
                  </section>
                </section>
              </b-card>
              <b-card class="">
                <section>
                  <h4 class="mb-1">Proxy Details</h4>

                  <section class="row">
                    <div class="col-6">
                      <p class="font-weight-bolder">Host</p>
                      <p>{{ network.proxy_host || '-' }}</p>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-bolder">Port</p>
                      <p class="text-capitalize">{{ network.proxy_port || '-' }}</p>
                    </div>
                  </section>
                  <section class="row">
                    <div class="col-6">
                      <p class="font-weight-bolder">Proxy Exclude List</p>
                      <p>{{ selectedProxyExcludeListName || '-' }}</p>
                    </div>
                  </section>
                </section>
              </b-card>
            </b-tab>
            <b-tab title="Activities" v-if="$can('Read', 'Activity')">
              <model-activities model="network" :uid="network.uid"></model-activities>
            </b-tab>
          </b-tabs>
        </div>
        <div class="col-3" v-if="$can('Update', 'Network') || $can('Delete', 'Network')">
          <b-card class="">
            <p class="font-weight-bolder">Actions</p>
            <b-btn v-if="$can('Update', 'Network')"
                   @click="$router.push({name: 'network-edit', params: {uid: network.uid}})"
                   variant="primary"
                   class="full-width mb-1">Update
            </b-btn>
            <b-btn v-if="$can('Delete', 'Network')"
                   @click="showNetworkModal('modal-delete-network')"
                   variant="danger"
                   class="full-width">Delete
            </b-btn>
          </b-card>
        </div>
      </div>
      <div v-else>Network not found</div>

      <b-modal id="modal-delete-network" title="Delete network" hide-footer>
        <delete-network-model
          v-if="network"
          :network="network"
          @close="closeModals"
          @deleted="networkDeleted"
        />
      </b-modal>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading networks"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from 'vuex'
import NetworkService from '@/services/NetworkService'
import DeleteNetworkModel from '@/views/networks/sections/DeleteNetworkModel.vue';
import ModelActivities from '../activities/ModelActivities.vue'
import EnterpriseService from "@/services/EnterpriseService";
import Vue from "vue";
import ProxyExcludeService from "@/services/ProxyExcludeService";

export default {
  name: 'Network',
  components: {
    DeleteNetworkModel,
    ModelActivities,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      proxyExcludeLists: [],
      selectedProxyExcludeListName: '',
      showPassphrase: false,
      showPassword: false,
    }
  },
  computed: {
    ...mapState({
      network: state => state.network.network,
    }),
    maskedPassphrase() {
      if (!this.network.passphrase) {
        return '-'
      }
      return this.showPassphrase ? this.network.passphrase : '•'.repeat(this.network.passphrase.length)
    },
    maskedPassword() {
      if (!this.network.eap_password) {
        return '-'
      }
      return this.showPassword ? this.network.eap_password : '•'.repeat(this.network.eap_password.length)
    },
    maskedServerCertificate() {
      if (!this.network.eap_server_ca_certificate) {
        return '-'
      }
      return this.network.eap_server_ca_certificate.length > 15 ? (this.network.eap_server_ca_certificate.substring(0, 15) + "...") : this.network.eap_server_ca_certificate
    },
    maskedClientCertificate() {
      if (!this.network.eap_client_certificate) {
        return '-'
      }
      return this.network.eap_client_certificate.length > 15 ? (this.network.eap_client_certificate.substring(0, 15) + "...") : this.network.eap_client_certificate
    },
  },
  mounted() {
    this.getNetwork()
    this.getProxyExcludedLists()
  },
  methods: {
    refreshNetwork() {
      this.closeModals()
      this.getNetwork()
    },
    closeModals() {
      this.$bvModal.hide('modal-update-network')
      this.$bvModal.hide('modal-delete-network')
    },
    networkDeleted() {
      this.$bvModal.hide('modal-delete-network')
      this.$router.push({ name: 'networks' });
    },
    async getNetwork() {
      this.loading = true
      await this.$store.dispatch('network/getNetwork', this.uid);
      this.loading = false;
    },
    async getProxyExcludedLists() {
      try {
        const listRequest = await ProxyExcludeService.getProxyExcludeLists();
        this.proxyExcludeLists = listRequest.data;

        const found = this.proxyExcludeLists.find(item => item.id === this.network.proxy_exclude_list_id);
        this.selectedProxyExcludeListName = found ? found.name : '';
      } catch (e) {
        Vue.$toast.error('Could not get proxy excluded lists, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showNetworkModal(modalName) {
      this.$bvModal.show(modalName)
    },
    deleteNetwork(network) {
      NetworkService.deleteNetwork(network.uid)
        .then(() => {
          this.$toast.success(`Deleted network ${network.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$router.push({ name: 'networks' })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not delete network, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    canShowPassphrase() {
      const s = this.network.security;
      return s !== 'None' && s !== 'WEP-8021X' && s !== 'WPA-EAP';
    },
    togglePassphrase() {
      this.showPassphrase = !this.showPassphrase
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
